.limits .mylabel {
    margin-bottom: 10px;
}

.limits .mylabelP {
    margin-bottom: 0;
}

.limits .mybtn {
    min-width: 80px;
    margin-top: 25px;
}

.limits .myinput {
    height: 35px;
    border-radius: 0;
}

.limits .mybtnItem {
    width: 100px;
    margin-right: 15px;
}

.limits .container {
    height: 100%;
    padding: 10px !important;
}


.limits .addBtn {
    margin: 10px 0;
}

.limits .detailTitle {
    font-size: 20px;
    color: #000;
}

.limits .detailDetail {
    font-size: 16px;
    color: #666;
    line-height: 2;
}

.limits .time {
    color: #666;
}

.limits .express {
    border: 1px solid #f0f0f0;
    margin-top: 15px;
}

.limits .expressBox {
    padding: 15px;
}

.limits .limitsbox {
    /* display: flex; */
    padding: 15px;
    border-bottom: 1px solid #efefef
}

.limitsbox .limitsitem3 {
    padding-bottom: 15px;
    padding-top: 15px;
    border-top: 1px solid #eee;
    margin-top: 15px;
}

.limitsbox .limitsitem3 span {
    font-size: 16px;
    font-weight: 700;
    /* padding-bottom: 15px; */

}

.limitsbox .limitsitem1 span {
    color: #000;
    /* font-weight: 500; */
}

.limitsbox .limitsitem2 {
    padding-left: 30px;
    margin-top: 10px;
    color: #333;
}

.limitsbox .limitsitem2 span {
    color: #666;
}

.limitsbox .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled span {
    cursor: not-allowed !important;

    color: rgba(0, 0, 0, 0.25);
}