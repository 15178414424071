.site-layout-background .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

.site-layout-background .trigger:hover {
    color: black;
}

.site-layout-background .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}


.site-layout .site-layout-background {
    background: #fff;
}

.site-layout .ant-layout-header {
    background-color: white;
    color: black;
}

.site-layout .ant-layout-header a {
    color: black;
}

.center {
    text-align: center;
}

.userCard {
    margin: 20px !important;
}

.userCard .ant-card-body {
    padding: 20px 20px 20px 0;
}

.logo {
    background: white;
    color: black;
    font-size: 18px;
    /* padding: 18px 0 17px 48px; */
    margin: 0 auto;
    /*margin-bottom: 20px;*/
    height: 63.75px;
    line-height: 63px;
    box-sizing: border-box;
    /*padding-left: 20px;*/
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo.logo-center {
    padding-left: 28px;

}

.image-wrap {
    max-width: 100%;
}

.image-wrap img {
    max-width: 100%;
}

.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner {
    color: #1890ff;
    background-color: #fff;
}

.ant-menu-inline-collapsed-tooltip .ant-tooltip-arrow-content {
    background-color: #fff;
}

.ant-menu-inline-collapsed-tooltip:hover .ant-tooltip-inner {
    color: #1890ff;
    background-color: #e6f7ff;
}

.ant-menu-inline-collapsed-tooltip:hover .ant-tooltip-arrow-content {
    background-color: #e6f7ff;
}


