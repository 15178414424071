.content {
    padding-top: 140px;
    display: flex;
    justify-content: center;
}

.ErrorPage-title {
    font-size: 40px;
    font-synthesis: style;
    font-weight: 600;
}

.ErrorPage-subtitle {
    font-size: 18px;
    margin-top: 10px;
}

.ErrorPage-primaryButton {
    margin-top: 10px;
}

.ErrorPage-otherButtonContainer {
    align-items: center;
    color: #0084ff;
    display: flex;
    font-size: 14px;
    margin-top: 20px;
    font-synthesis: style;
    font-weight: 600;
}

.goLogin {
    margin-left: 0.5em;
}