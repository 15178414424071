.areaHospitalSelect {
    margin-top: 8px;
}

.recordIcon {
    color: #868686;
    margin: 0 5px;
    font-size: 15px;
    cursor: pointer;
    line-height: 25px;
}

.warnIcon {
    color: red;
    margin: 0 5px;
    font-size: 20px;
    cursor: pointer;
}

.statusDiv {
    display: flex;
    justify-content: center;
}

.optMenu {
    float: right;
}

.upload-wrap {
    display: inline-block;
    position: relative;
    width: 120px;
    height: 32px;
    /*overflow: hidden;*/
    border: 1px solid #d9d9d9;
    text-align: center;
    line-height: 32px;
    /*margin-top: 5px;*/
    cursor: pointer;
}

.fileUploader {
    display: none;
}

.upload-text {
    display: inline-block;
}


.mylabel {
    margin-bottom: 10px;
}

.mylabelP {
    margin-bottom: 0;
}

.backBtn {
    min-width: 80px;
    margin-top: 25px;
}

.saveBtn {
    min-width: 80px;
    margin-top: 25px;
}

.mybtn {
    float: right;
    min-width: 80px;
    margin-top: 25px;
    margin-left: 5px;
}

.detailBackBtn {
    min-width: 80px;
    margin-top: 25px;
}

.myinput {
    height: 35px;
    border-radius: 0;
}

.mybtnItem {
    width: 100px;
    margin-right: 15px;
}

.container {
    height: 100%;
    padding: 10px !important;
}


.addBtn {
    margin: 0 0;
}

.detailTitle {
    font-size: 18px;
    color: #000;
}

.detailDetail {
    font-size: 16px;
    color: #666;
    line-height: 2;
}

.time {
    color: #666;
}

.express {
    border: 1px solid #f0f0f0;
    margin-top: 15px;
}

.expressBox {

    padding: 15px;

}


