/*.head button{*/
/*    height: 35px;*/
/*    float: right;*/
/*    margin-right: 11px;*/
/*    margin-top: 5px;*/
/*}*/
.head {
    background-color: white;
    height: 50px;
}

.mylabel {
    margin-bottom: 10px;
}

.mylabelP {
    margin-bottom: 0;
}

.mybtn {
    width: calc(100% - 30px);
    margin-left: 15px;
    height: 50px;
}

.myinput {
    height: 35px;
    border-radius: 0;
}

.container {
    height: 100%;
    padding: 10px !important;
    width: 50%;
    margin: 0 auto;
}

.mycard {
    margin-top: 10px;
    box-shadow: none !important;
}

.activeDiv {
    width: 70%;
    background-color: #D9D9D9;

    line-height: 30px;
    padding: 10px 20px;
}

.star::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.poster {
    /*background: url('./images/bg1@2x.png') no-repeat;*/
    height: 808px;
    width: 760px;
    font-size: 32px;
    color: #FFFFFF;
    line-height: 32px;
}

.bg2 {
    /*background: url('./images/ibg2.png') no-repeat;*/
    background-size: 100% 100%;
    width: 584px;
    height: 128px;
    margin: 0 auto;
    text-align: center;
    margin-top: 32px;
}

.qrcode {
    position: absolute;
    bottom: 90px;
    right: 36px;
}

.ititle {
    padding-top: 332px;
    margin-left: 48px;
    margin-bottom: 32px;
}

.ifooter {
    text-align: center;
    margin-top: 20px;
    color: #ffffff;
    font-size: 24px;
}

.icode {
    color: #D48220;
    padding-top: 24px;
    margin-bottom: 16px;
    font-size: 26px;
}

.code2 {
    font-size: 36px;
}

.idesc {
    width: 556px;
    text-indent: 64px;
    margin-left: 48px;
    line-height: 54px;
    font-size: 32px;
}

.uuu {
    text-decoration: underline;
}

.ant-col-5 {
    display: block;
    flex: 0 0 30.833333%;
    max-width: 30.83333333%;
}