.addBtn {
    margin: 10px 0;
}

.myinput {
    height: 35px;
    border-radius: 0;
}

.mylabel {
    margin-bottom: 10px;
}

.container {
    height: 100%;
    padding: 10px !important;
}

.mycard {
    margin-top: 10px;
}

.detailLabel {
    width: 80px;
    text-align: right;
}

.mgRight10 {
    margin-right: 10px;
}
