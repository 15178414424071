.fileList {
    position: relative;
    height: 66px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    margin-top: 8px;
    font-size: 14px;
}

.fileList .box {
    display: flex;
}

.fileList .icon {
    flex: none;
    cursor: pointer;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fileList .img {
    max-width: 48px;
    max-height: 48px;
    /*margin: 12px;*/
}

.fileList .fileName {
    cursor: pointer;
    flex: 3;
    height: 100%;
    line-height: 47px;
    color: #1890ff;
    padding: 0 8px;
    margin: 0 0 0 8px;
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.imgDiv {
    padding: 5px 5px 5px 0;
}
