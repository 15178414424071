/* 用户右侧详情 */
.personTop {
    padding-bottom: 24px;
    border-bottom: 1px solid #e8e8e8;
    text-align: center;
}

.personHeader {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    vertical-align: top;
}

.personNameBox {
    margin-top: 8px;
    height: 22px;
    line-height: 22px;
    position: relative;
}

.personName {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    line-height: 22px;
    height: 22px;
    display: inline-block;
    position: relative;
}

.sexImg {
    width: 12px;
    height: 12px;
    background: url(/src/asset/sex1.png) top center no-repeat;
    background-size: 12px 12px;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    right: -12px;
    top: 50%;
    transform: translateY(-50%);
}

.sexImg.nv {
    background-image: url(/src/asset/sex2.png);

}

.person-bottom {
    display: flex;
}

.person-bottom > div {
    flex: 1;
    padding-left: 8px;

}

.person-bottom .item {
    padding-top: 24px;
    line-height: 20px;
    font-size: 12px;
}

.person-bottom .title {
    font-size: 14px;
    font-weight: 400;
    text-align: LEFT;
    color: #262626;
    line-height: 20px;
    margin-top: 8px;
    height: 20px;


}

.person-bottom .title.number {
    font-family: DIN Alternate, DIN Alternate-Bold;
    font-weight: 700;
}

.person-right {
    margin-left: 24px;
}

.person-bottom .title span {
    font-size: 8px;
    font-weight: 500;
}

.company {
    font-size: 11px;
    margin-top: 4px;
}

.personBox {
    height: calc(100vh - 120px);
    overflow: auto;
    width: 280px;
    /* margin-left: 24px; */
    padding: 40px 32px;
    /* font-size: 11px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #8c8c8c;
    line-height: 19px; */
    box-sizing: border-box;
    background-color: #fff;
    font-size: 14px;
    position: relative;
}

.service-type {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    width: 80px;
}

.person-bottom .title span {
    font-size: 14px;
    font-weight: 400;
    text-align: LEFT;
    color: #262626;
    line-height: 20px;

}