.full-screen-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    overflow: hidden;

}

.modal-content {
    /*background-color: #fff;*/
    padding: 45px;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
}

p {
    margin: 0 !important;
}

.noReader {
    width: 100%;
    height: 100%;
    line-height: 245px;
    text-align: center;
    font-size: x-large;
}
