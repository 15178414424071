.remark {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.remark-content {
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 384px;
    min-height: 348px;
    background: #ffffff;
    border-radius: 8px;
    padding: 8px 0 24px;
    color: #262626;
    font-size: 16px;
    display: flex;
    flex-direction: column;
}

.remark-content .title {
    font-size: 20px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: CENTER;
    color: #262626;
    line-height: 58px;
    height: 58px;
    border-bottom: 1px solid #f2f2f2;

}

.remark-content .content {
    padding: 24px 20px 0 20px;
    flex: 1;

}

.remark-content .item {
    list-style: none;
    margin-bottom: 16px;
    width: 100%;
}

.remark-content .footer {
    text-align: center;
    padding: 24px 32px 0 32px;
}

.remark-content .footer button {
    width: 100%;
    height: 40px;
    border-radius: 2px;
}

.account-box {
    padding-left: 30px;
    padding-right: 30px;
    width: 469px;
}

.account-box .title-tip {
    font-size: 13px;
    font-weight: 400;
    text-align: LEFT;
    color: #ff4d4f;
    line-height: 21px;
}

.account-box input {
    height: 32px;
}

.remark-content .ant-form-item-control-input-content span {
    line-height: 32px;
}

.remark-content .ant-form-item {
    margin-bottom: 16px;
}
