.logoImg {
    width: 50px;
    /*margin-top: -8px;*/
}

.menuLogoImg {
    width: 30px;
    margin-top: -8px;
}

.container {
    min-height: 100vh;
    padding: 0 0.5rem;
    display: flex;
    /* flex-direction: column;
    justify-content: center; */
    align-items: center;
    height: 100vh;
    box-sizing: border-box;
    background: url(../asset/login_bg.png) left top no-repeat;
    background-size: 100% 100%;
    padding-left: 12%;
    box-sizing: border-box;
}

.main {
    padding: 5rem 0;
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    min-width: 423px;

    box-sizing: content-box;

}


.footer {
    width: 100%;
    height: 100px;
    border-top: 1px solid #eaeaea;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.title a {
    color: #0070f3;
    text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
    text-decoration: underline;
}

.title {
    margin: 0;
    /*line-height: 50px !important;*/
    font-size: 36px;
    display: flex;
    align-content: center;
}

.title,
.description {
    text-align: center;
}

.description {
    line-height: 1.5;
    font-size: 1.5rem;
}

.code {
    background: #fafafa;
    border-radius: 5px;
    padding: 0.75rem;
    font-size: 1.1rem;
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 384px;
    margin-top: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 20px 0 rgba(38, 38, 38, 0.10);
    /* padding: 32px 24px 40px; */
    box-sizing: border-box;
}

.tab {
    padding: 20px 24px 40px;
}

.tabspan {
    font-size: 16px;
}

.ant-tabs-nav-wrap {
    padding-left: 24px;
}

.card {
    margin: 1rem;
    padding: 1.5rem;
    text-align: left;
    color: inherit;
    text-decoration: none;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
    width: 45%;
}

.card:hover,
.card:focus,
.card:active {
    color: #0070f3;
    border-color: #0070f3;
}

.card h2 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
}

.card p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.5;
}

.logo {
    height: 1em;
    margin-left: 0.5rem;
}

@media (max-width: 600px) {
    .grid {
        width: 100%;
        flex-direction: column;
    }
}
