.desc {
    background-color: #f3f4f7;
    width: 90%;
    padding: 10px;
}

.descItem {
    padding-bottom: 0 !important;
    color: #929294 !important;
}

.showDiv {
    display: flex;
    /*width: 50%;*/
    /*background-color: #f3f4f7;*/
}

.cardItem {
    background-color: #f3f4f7;
    height: 220px;
}

.editButton {
    margin: 10px;
}
