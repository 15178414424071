.prompt {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    /*color: #f2605a;*/
}

.title_img {
    padding-top: 50px;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 50px;
}

.title {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 44px;
    color: #f2605a;
    font-weight: 900;
}

.goLogin {
    padding-top: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.goLoginButton {
    height: 50px;
    width: 300px;
    margin: 0 auto;
    text-align: center;
    font-size: 18px;
}