.RadioPadding {
    padding-top: 5px;
}

.antBtnPadding {
    padding: 0 0 !important;
}

.imgItem {
    margin-left: 50px;
}

.mylabel {
    margin-bottom: 10px;
}

.mylabelP {
    margin-bottom: 0;
}

.mybtn {
    min-width: 80px;
    margin-top: 25px;
}

.myinput {
    height: 35px;
    border-radius: 0;
}

.mybtnItem {
    width: 100px;
    margin-right: 15px;
}

.container {
    height: 100%;
    padding: 10px !important;
}


.addBtn {
    margin: 10px 0;
}

.detailTitle {
    font-size: 18px;
    color: #000;
}

.detailDetail {
    font-size: 16px;
    color: #666;
    line-height: 2;
}

.time {
    color: #666;
}

.express {
    border: 1px solid #f0f0f0;
    margin-top: 15px;
}

.expressBox {

    padding: 15px;

}

.mycard {
    margin-top: 10px;
}

.fAudit {
    float: right;
}
