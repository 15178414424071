.constant {
    text-align: center;
}

.ok {
    width: 150px;
}

.downloadButton {
    border-bottom: #1890ff 1px solid;
    padding: 0 0 !important;
}

.downloadButton:hover {
    border-bottom: #1890ff 1px solid;
    padding: 0 0 !important;
}