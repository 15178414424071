.addBtn {
    margin: 10px 0;
}

.myinput {
    height: 35px;
    border-radius: 0;
}

.mylabel {
    margin-bottom: 10px;
}

.container {
    height: 100%;
    padding: 10px !important;
}

.mycol {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.mynum {
    display: inline-block;
}

.mynum2 {
    font-size: 12px;
    position: relative;
    top: -20px;
    left: 40px;
}

.mgRight10 {
    margin-right: 10px;
}

.mylink::before {
    content: "";
    width: 7px;
    height: 7px;
    border: solid #1890ff;
    border-width: 0 1px 1px 0;
    transform: translate(-50%, -50%) rotate(-45deg);
    position: absolute;
    left: 60%;
    top: 58%;
}

.mylink2::before {
    content: "";
    width: 7px;
    height: 7px;
    border: solid #1890ff;
    border-width: 0 1px 1px 0;
    transform: translate(-50%, -50%) rotate(-45deg);
    position: absolute;
    left: 61.5%;
    top: 58%;
}

.mylink3::before {
    content: "";
    width: 7px;
    height: 7px;
    border: solid #1890ff;
    border-width: 0 1px 1px 0;
    transform: translate(-50%, -50%) rotate(-45deg);
    position: absolute;
    left: 56.5%;
    top: 58%;
}
