.braft-modal {
    z-index: 1001;
}

.bf-container {
    border: 1px solid #d9d9d9;
}

.bf-toggle-external-form {
    display: none;
}
