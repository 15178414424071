
.goRegister {
    text-align: end;
}

.mylabel {
    margin-bottom: 24px;
    width: 100%;
}

.form {
    width: 100%;
    padding-top: 20px;
}

.mybtn {
    height: 40px;
    width: 100%;
    font-size: 14px;
}

.btn_box {
    padding-top: 24px;
}

.myinput {
    height: 40px;
    border-radius: 2px;

}

.container {
    height: 100%;
    padding: 10px !important;
}

.mycontent {
    background: #ffffff;
    padding: 0 20px;
}

.custom::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

/* 验证码登陆 */
.code_box {
    position: relative;
}

.code_box span {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 94px;
    line-height: 40px;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    color: #1890FF;
    user-select: none;
}

.code_box span::before {
    content: "";
    position: absolute;
    left: 0;
    height: 12px;
    border: 1px solid #dedede;
    top: 14px;
}

.code_box .active {
    color: #ccc;
}