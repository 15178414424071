.content {
    display: flex;
    padding: 24px;
    min-height: 360px
}

.contentRight {
    width: 280px;
    margin-left: 24px;
    /* padding: 40px 32px; */
    font-size: 11px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #8c8c8c;
    line-height: 19px;
}

.contentLeft {
    flex: 1;
    padding: 24px;
}

.margBot {
    margin-bottom: 13px;
}

.jobTitle {
    font-size: 14px;
    font-weight: 400;
    text-align: LEFT;
    color: #262626;
    line-height: 22px;
    padding-left: 10px;
    position: relative;
    margin-bottom: 24px;
}

.jobTitle::after {
    content: "";
    position: absolute;
    width: 0;
    height: 10px;
    border-left: 2px solid #1890ff;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

}