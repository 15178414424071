.upload-wrap {
    display: inline-block;
    position: relative;
    width: 124px;
    padding: 3px 5px;
    overflow: hidden;
}

.file-uploader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    outline: none;
    opacity: 0;
    background-color: transparent;
}

.upload-text {
    display: inline-block;
    margin-left: 5px;
}

.upload-tip {
    display: inline-block;
    margin-left: 10px;
    color: #999;
}