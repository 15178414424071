.estimate {
    margin: 16px 0;
    float: right;
}

.popover {
    width: 550px;
    max-height: 500px;
}

.antBtnPadding {
    padding: 0 0 !important;
}

.areaHospitalSelect {
    margin-top: 8px;
}

.statusDiv {
    display: flex;
    justify-content: center;
}


.recordIcon {
    color: #868686;
    margin: 0 5px;
    font-size: 15px;
    cursor: pointer;
    line-height: 25px;
}

.warnIcon {
    color: red;
    margin: 0 5px;
    font-size: 20px;
    cursor: pointer;
}

.mylabel {
    margin-bottom: 10px;
}

.mylabelP {
    margin-bottom: 0;
}

.refuseBtn {
    min-width: 80px;
    margin-top: 25px;
}

.backBtn {
    min-width: 80px;
    margin-top: 25px;
}

.screeningSave {
    min-width: 80px;
    margin-top: 25px;
}

.postSave {
    min-width: 80px;
    margin-top: 25px;
}

.mybtn {
    float: right;
    min-width: 80px;
    margin-top: 25px;
    margin-right: 20px;
}

.myinput {
    height: 35px;
    border-radius: 0;
}

.mybtnItem {
    width: 100px;
    margin-right: 15px;
}

.container {
    height: 100%;
    padding: 10px !important;
}


.addBtn {
    margin: 10px 0;
}

.detailTitle {
    font-size: 18px;
    color: #000;
}

.detailDetail {
    font-size: 16px;
    color: #666;
    line-height: 2;
}

.time {
    color: #666;
}

.express {
    border: 1px solid #f0f0f0;
    margin-top: 15px;
}

.expressBox {

    padding: 15px;

}

