.mylabel {
    margin-bottom: 10px;
}

.mylabelP {
    margin-bottom: 0;
}

.mybtn {
    width: calc(100% - 30px);
    margin-left: 15px;
    height: 50px;
}

.myinput {
    height: 35px;
    border-radius: 0;
}

.container {
    height: 100%;
    padding: 10px !important;
}
